import { isArray } from "lodash";

class Helper{
    static wordLimiter = (text, len)=>{
        if(!text) return '';
        let truncateText = text.split(" ").splice(0,len).join(" ");
        return truncateText.length < text.length ? `${truncateText}...`:truncateText;
    }

    static getUrlParam = (paramName)=>{
        let request = window.location.search,
        urlParams = new URLSearchParams(request);
        return urlParams.get(paramName);
    }
    static generateFilters = ()=>{
        let request=window.location.search,
        urlParams=new URLSearchParams(request),
        params=urlParams.keys(),data=[];
        for(let a of params){
            let r=urlParams.get(a),
            e=r.split(",");
            data.push({[a]:e});
        }
        return data;
    }
    static queryStringToArray = () =>{
        let request=window.location.search,
        urlParams=new URLSearchParams(request),
        params=urlParams.keys(),data=[];
        for(let a of params){
            let r=urlParams.get(a);
            data.push({[a]:r});
        }
        return data;
    }
    
    static getUrlParamKeys = ()=>{
        let request=window.location.search,
        urlParams=new URLSearchParams(request);
        return urlParams.keys();
    }

    static getLastSegmentUrl = ()=>{
        let url = window.location.pathname;
        return url.substr(url.lastIndexOf('/')+1); 
    }

    static createQueryString = (data)=> {
        let queryStringArray = [];
        data.forEach(elem => {
            let name = Object.keys(elem)[0];
            let value = isArray(elem[name]) ? 
            elem[name].join(','):elem[name]
            value &&
            queryStringArray.push([`${name}=${value}`]);
        });
        return queryStringArray.join('&');
    }
}
export default Helper