import React from 'react';
import CustomDatePicker from './CustomDatePicker';
import CustomSelectAddress from './CustomSelectAddress';
class FormOptionRegister extends React.Component {
    handleSubmitForm=(e)=>{
        e.preventDefault();
        let {city,district,ward,address,birthday,gender} = e.target,
        data = {
            city:city.value,
            district:district.value,
            address:address.value,
            birthday:birthday.value,
            gender:gender.value,
            ward:ward.value
        };
        this.props.handleSubmitRegStep2(data);
    }

    handleChangeDate=(e)=>{
        this.props.handleChangeDate(e);
    }
    handleIgnore = ()=>{
        window.location.href='/';
    }
    
    render(){
        let {selectedDate, selectedCity, alertMsg, selectedDistrict, addressText} = this.props;
        return (
            <div className="col-12 col-md-10 offset-md-1 login-form-3">
                <h3>Bước 2: Nhập thông tin cơ bản</h3>
                <p className="text-center text-dark">* Các thông tin này là tùy chọn, 
                bạn có thể chọn bỏ qua và thiết lập sau.</p>
                <form id="form-reg-step2" onSubmit={this.handleSubmitForm}>
                    {alertMsg?alertMsg.register2??null:null}
                    <div>
                        <label className="font-weight-bold">Địa chỉ</label>
                        <CustomSelectAddress city="city" district="district" address='address'
                        ward="ward"
                        selectedCity={selectedCity} selectedDistrict={selectedDistrict}
                        addressText={addressText} />
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold mr-4">Ngày sinh</label>
                                <CustomDatePicker selectedDate={selectedDate} name="birthday" dateFormat="dd/MM/yyyy"
                                minDate={new Date('1970-01-01')} maxDate={new Date()} handleChangeDate={this.handleChangeDate} />
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold mr-4">Giới tính</label>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" 
                                    type="radio" name="gender" id="gridRadios1" 
                                    defaultValue="1" defaultChecked={true} />
                                    <label className="form-check-label" htmlFor="gridRadios1">
                                        Nam
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" 
                                    type="radio" name="gender" id="gridRadios2" defaultValue="0" />
                                    <label className="form-check-label" htmlFor="gridRadios2">
                                        Nữ
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="form-group mt-2 d-flex justify-content-between">
                        <button onClick={this.handleIgnore} type="button" className="btnIgnore">Bỏ qua </button>
                        <button type="submit" className="btnSubmit">Đăng Ký </button>
                    </div>
                </form>
            </div>
    
        )
    }
}
export default FormOptionRegister;