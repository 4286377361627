import React from 'react';
class CustomSelectAddress extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            isLoaded: null,
            cityData:[],
            districtData:[],
            wardData:[],
            selectedCity:'00',
            selectedDistrict:'000',
            selectedWard:'00000',
        }
    }

    componentDidMount=()=>{
        fetch("/info/getAjaxCity")
        .then(res=>res.json())
        .then(res=>{
            this.setState({
                isLoaded:true,
                cityData:res,
            });
        })
    }

    handleChangeCity=(e)=>{
        let city = e.target.value,
        filterDistrict = [],
        filterWard = [],
        selectedDistrict = '00',
        selectedWard = '00000';
        city !== '00' && (
            fetch('/info/getAjaxDistrict',{
                method:'POST',
                body: JSON.stringify({city:city})
            }).then(res=>res.json())
            .then(res=>{
                filterDistrict = res;
                this.setState({
                    districtData:filterDistrict,
                    wardData:filterWard,
                    selectedCity:city,
                    selectedDistrict:selectedDistrict,
                    selectedWard:selectedWard
                });
            })
        );
    }
    handleChangeDistrict=(e)=>{
        let district = e.target.value,
        filterWard = [],
        selectedWard = '00000';
        district !== '000' && (
            fetch('/info/getAjaxWard',{
                method:'POST',
                body: JSON.stringify({district:district})
            }).then(res=>res.json())
            .then(res=>{
                filterWard = res;
                this.setState({
                    wardData:filterWard,
                    selectedDistrict:district,
                    selectedWard:selectedWard
                });
            })
        );
    }

    handleChangeWard = (e)=>{
        let ward = e.target.value;
        this.setState({
            selectedWard:ward
        })
    }
    
    render(){
        let {city, district, address, ward, addressText} = this.props;
        const {cityData, districtData, wardData,
        selectedCity, selectedDistrict, selectedWard} = this.state;
    
        let options = cityData.length && cityData.map((item,index)=>(
            <option value={item.id} key={index}>{item.name}</option>
        )),
        optionsDistrict = districtData.length ? districtData.map((item, index)=>(
            <option value={item.maqh} key={index}>{item.name}</option>
        )):null,
        optionsWard = wardData.length ? wardData.map((item, index)=>(
            <option value={item.xaid} key={index}>{item.name}</option>
        )):null;
        return (
            <div className="row">
                <div className="col-12 col-md-6 form-group">
                    <select onChange={this.handleChangeCity} value={selectedCity} className="form-control" name={city??'city'}>
                        <option value="00">-- Chọn thành phố --</option>
                        {options}
                    </select>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <select value={selectedDistrict}
                    onChange={this.handleChangeDistrict}
                    className="form-control" name={district??'district'}>
                    <option value="000">-- Chọn quận/huyện --</option>
                        {optionsDistrict}
                    </select>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <select value={selectedWard} className="form-control" 
                    onChange={this.handleChangeWard}
                    name={ward??'ward'}>
                        <option value="00000">-- Chọn phường/xã/thị trấn --</option>
                        {optionsWard}
                    </select>
                </div>

                <div className="col-12 col-md-6 form-group">
                    <input type="text" defaultValue={addressText} 
                    placeholder="Số nhà, tên đường" 
                    className="form-control" name="address" required
                    name={address??'address'} />
                </div>
            </div>
        )
    }
}
export default CustomSelectAddress;