import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { range } from 'lodash';
class CustomDatePicker extends React.Component{
    handleChangeDate = (date)=>{
        this.props.handleChangeDate(date)
    }

    render(){
        let {selectedDate, name, dateFormat, minDate, maxDate} = this.props;
        const years = range(minDate.getFullYear(), maxDate.getFullYear()+1, 1);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <DatePicker selected={selectedDate} 
            className="form-control"
            onChange={this.handleChangeDate} 
            name={name} dateFormat={dateFormat}
            minDate={minDate}
            maxDate={maxDate}
            renderCustomHeader={({date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled})=> {
                    return (
                        <div className="input-group mb-3 w-100">
                            <div className="input-group-prepend">
                                <button type="button" 
                                onClick={decreaseMonth} 
                                disabled={prevMonthButtonDisabled} 
                                className="btn btn-secondary btn-sm">
                                    <i className="fa fa-angle-left" />
                                </button>
                            </div>
                            <select 
                            value={date.getFullYear()}
                            onChange={({target: {value}})=> changeYear(value)}
                            className="form-control form-control-sm">
                                {years.map(option=>(
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                                <select 
                                value={months[date.getMonth()]} 
                                onChange={({target: {value}})=>changeMonth(months.indexOf(value))}
                                className="form-control form-control-sm">
                                    {months.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                    ))}
                                </select>
                            <div className="input-group-append">
                                <button onClick={increaseMonth} 
                                disabled={nextMonthButtonDisabled}
                                type="button" className="btn btn-secondary btn-sm">
                                    <i className="fa fa-angle-right" />
                                </button>            
                            </div>
                        </div>
                    )
                }
                
            } />
        );
    }
}
export default CustomDatePicker;