import React from 'react';
class FormRegister extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            isInvalidRepass:false,
            showPass: false,
            showRePass: false,
        }
    }
    handleSubmitForm=(e)=>{
        e.preventDefault();
        let {fullname,email,phone,password,re_password} = e.target,
        data = {
            fullname:fullname.value,
            email:email.value,
            phone:phone.value,
            password:password.value,
            re_password:re_password.value
        };
        this.props.handleSubmitRegStep1(data);
    }

    handleCheckRepass = (e)=>{
        let form = e.target.form,
        password = form.password.value,
        rePassword = e.target.value,
        isInvalidRepass = password && (password !== rePassword); 
        this.setState({
            isInvalidRepass:isInvalidRepass
        })
    }
    handleShowPass=()=>{
        this.setState({
            showPass:!this.state.showPass
        })
    }
    handleShowRePass=()=>{
        this.setState({
            showRePass:!this.state.showRePass
        })
    }
    handleCheckValidNumber=(e)=>{
        let number = e.target.value;
        e.target.value = number.substring(0,10).replace(/[^0-9]*/g,'');
    }
    
    render(){
        let {alertMsg} = this.props;
        let {showPass} = this.state,
        fieldPass = showPass ? 'text':'password';
        return (
            <div className="col-md-6 login-form-2">
                <h3>Đăng ký thành viên</h3>
                <form id="form-reg" onSubmit={this.handleSubmitForm}>
                    {alertMsg?alertMsg.register??null:null}
                    <div className="form-group input-group">
                        <input type="text" className="form-control" 
                        placeholder="Họ tên*" tabIndex="1" required name="fullname" defaultValue="" />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="fas fa-user" />
                            </span>
                        </div>
                    </div>
                    <div className="form-group input-group">
                        <input type="text" className="form-control" 
                        placeholder="Email*" required name="email" tabIndex="2" defaultValue="" />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                @
                            </span>
                        </div>
                    </div>
                    <div className="form-group input-group">
                        <input onChange={this.handleCheckValidNumber} type="text" className="form-control" 
                        placeholder="Số điện thoại*" required tabIndex="3" name="phone" defaultValue="" />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="fas fa-phone" />
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input type={fieldPass} required name="password" className="form-control" 
                            placeholder="Mật khẩu *" defaultValue="" tabIndex="4" />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-light" onClick={this.handleShowPass}>
                                    <i className="far fa-eye" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input type={this.state.showRePass?'text':'password'} required name="re_password" className={`form-control 
                            ${this.state.isInvalidRepass ? 'is-invalid':''}`} 
                            tabIndex="5" placeholder="Nhập lại mật khẩu *" onChange={this.handleCheckRepass} defaultValue="" />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-light" onClick={this.handleShowRePass}>
                                    <i className="far fa-eye" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group text-right">
                        <button tabIndex="6" type="submit" className="btnSubmit">Đăng Ký </button>
                    </div>
                </form>
            </div>
    
        )
    }
}
export default FormRegister;