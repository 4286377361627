import React from 'react';
class FormLogin extends React.Component {
    handleLogin = (e)=>{
        e.preventDefault();
        let {id,password,save_login} = e.target,
        saveLogin = save_login.checked;
        this.props.handleLogin({id:id.value,password:password.value,saveLogin:saveLogin});
    }
    
    render(){
        let {alertMsg} = this.props;
        return (
            <div className="col-md-6 login-form-1 bg-light">
                <h3>Đăng nhập</h3>
                
                <form method="POST" id="login-form" onSubmit={this.handleLogin}>
                    {alertMsg?alertMsg.login??null : null}
                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fas fa-envelope" />
                            </span>
                        </div>
                        <input type="text" className="form-control" 
                        placeholder="Email/Số điện thoại*" name="id" required defaultValue="" />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i className="fas fa-mobile-alt" />
                            </span>
                        </div>
                    </div>
                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fas fa-key"/>
                            </span>
                        </div>
                        <input type="password" name="password" className="form-control" 
                        placeholder="Mật khẩu *" required defaultValue="" />
                        
                    </div>
                    <div className="form-group row m-0">
                        <div className="col-md-7 mb-3">
                            <button type="submit" className="btnSubmit w-100">Đăng nhập</button>
                        </div>
                        <div className="col-md-5">
                            <a href="/quen-mat-khau" className="ForgetPwd">Quên mật khẩu?</a>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input defaultValue="1" type="checkbox" className="form-check-input" id="saveLogin" name="save_login" />
                            <label htmlFor="saveLogin" className="form-check-label">Ghi nhớ đăng nhập</label>
                        </div>
                    </div>
                    
                </form>
            </div>
        )
    }
}
export default FormLogin;