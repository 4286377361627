import React from 'react';
class AlertComponent extends React.Component {
    handleCloseAlert=()=>{
        this.props.handleCloseAlert(true)
    }
    render(){
        let {type, text} = this.props,
        alertType = `alert-${type}`;
        return (
            <div className={`alert ${alertType}`} role="alert">
                <span className="alertText">{text}</span>
                <button onClick={this.handleCloseAlert} type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        )
    }
}
export default AlertComponent;