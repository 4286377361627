import React from 'react';
import FormLogin from './FormLogin';
import FormRegister from './FormRegister';
import FormOptionRegister from './FormOptionRegister';
class Content extends React.Component {
    handleSubmitRegStep1=(data)=>{
        this.props.handleSubmitRegStep1(data);
    }

    handleSubmitRegStep2=(data)=>{
        this.props.handleSubmitRegStep2(data);
    }
    handleChangeDate = (date)=>{
        this.props.handleChangeDate(date);
    }
    handleLogin = (data)=>{
        this.props.handleLogin(data);
    }
    handleCloseAlert = (flag)=>{
        this.props.handleCloseAlert(flag);
    }
    render(){
        let {regStep} = this.props,
        regForm = regStep === 'step1' ? 
        <div className="login-container row">
            <FormLogin handleLogin={this.handleLogin} alertMsg={this.props.alertMsg} />
            <FormRegister alertMsg={this.props.alertMsg} handleSubmitRegStep1={this.handleSubmitRegStep1} />
        </div> : 
        <div className="login-container row">
            <FormOptionRegister alertMsg={this.props.alertMsg} selectedDate={this.props.selectedDate} 
            handleChangeDate={this.handleChangeDate} handleSubmitRegStep2={this.handleSubmitRegStep2} />
        </div>
        return (
            <div className="container bg-white">
                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1">
                        {regForm}
                    </div>
                </div>
            </div>
        )
    }
}
export default Content;