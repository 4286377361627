import React from 'react';
import Content from './Component/Content';
import Cookie from 'react-cookies';
import BreadCrumb from './Component/BreadCrumb';
import Helper from './Component/Helper';
import NumberFormat from "react-number-format";
import ReactDOMServer from 'react-dom/server';
import FacebookLogin from 'react-facebook-login';
import AlertComponent from './Component/AlertComponent';
class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      alertMsg:null,
      faceLogin:false,
      regStep:'step1',
      selectedDate: new Date('2000-01-01'),
      insertedId:0
    };
  }

  handleShowAlert = (alertMsg)=>{
    this.setState({
      alertMsg:alertMsg
    })
  }

  handleCloseAlert = (flag)=>{
    flag && this.setState({alertMsg:null})
  }

  handleChangeDate=(date)=>{
      this.setState({
        selectedDate:new Date(date)
      })
  }

  handleLogin = (data)=>{
      if(!data || !data.id || !data.password)
      {
        this.setState({
          alertMsg:{
            login:<AlertComponent handleCloseAlert={this.handleCloseAlert} type="danger" renderText="Nhập thông tin đăng nhập" />
          }
        })
        return false;
      }
      const saveLogin = data.saveLogin ?? false;
      //Call post ajax
      fetch("/login/process", {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(res=>res.json())
        .then((result)=>{
          let expires = new Date();
          saveLogin && expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
          let expiredTime = saveLogin ? expires : 0;
          if(result.response === 'success'){
              Cookie.save('pbuniq',result.data.id,{path: '/', expires: expiredTime});
              Cookie.save('uname',result.data.encode_key,{path: '/', expires: expiredTime});
              let redirectUrl = Helper.getUrlParam('redirect') ? decodeURIComponent(Helper.getUrlParam('redirect')):'/';
              window.location.href=redirectUrl;
          }
          else{
              this.setState({
                alertMsg:{
                  login:<AlertComponent type="danger" handleCloseAlert={this.handleCloseAlert} 
                  text={result.msg} />
                }
              })
              return false;
          }
        },
        (error)=> {
            console.log(error);
        })
  }

  handleSubmitRegStep1 = (data)=>{
      if(!data.email || !data.fullname || !data.phone)
      {
        this.setState({
          alertMsg:{
            register:<AlertComponent handleCloseAlert={this.handleCloseAlert} text="Nhập thông tin đăng ký" type="danger" /> 
          }
        });
        return false;
      }
      if(data.password.length < 8)
      {
        this.setState({
          alertMsg:{
            register:<AlertComponent handleCloseAlert={this.handleCloseAlert} text="Mật khẩu tối thiểu 8 ký tự" type="danger" /> 
          }
        });
        return false;
      }
      if(data.password !== data.re_password)
      {
        this.setState({
          alertMsg:{
            register:<AlertComponent handleCloseAlert={this.handleCloseAlert} text="Mật khẩu nhập lại không đúng" type="danger" /> 
          }
        });
        return false;
      }
      //Call API put data
      //Call post ajax
      let refer = Helper.getUrlParam('refer') ?? '',
      postData = {
        fullname:data.fullname,
        email:data.email,
        phone:data.phone,
        password:data.password,
        refer:refer
      }
      fetch("/member/register", {
          method: 'POST',
          body: JSON.stringify(postData)
        })
        .then(res=>res.json())
        .then((result)=>{
          if(result.response === 'success'){
              Cookie.save('pbuniq',result.data.id,{path: '/'});
              Cookie.save('uname',result.data.encode_key,{path: '/'});
              this.setState({
                regStep:'step2',
                insertedId: result.data.id
              });
              return true
          }
          else{
              this.setState({
                alertMsg:{
                  login:<AlertComponent type="danger" handleCloseAlert={this.handleCloseAlert} 
                  text={result.msg} />
                }
              })
              return false;
          }
        },
        (error)=> {
            console.log(error);
        })
  }

  handleSubmitRegStep2 = (data)=>{
    if(data.city === '00' || data.district==='000' || data.ward === '00000')
    {
        this.setState({
          alertMsg:{
            register2:<AlertComponent text="Chọn tỉnh thành, quận huyện và phường xã" type="danger" handleCloseAlert={this.handleCloseAlert} />
          }
        });
        return false;
    }
    if(!data.address)
    {
        this.setState({
          alertMsg:{
            register2:<AlertComponent text="Nhập địa chỉ chi tiết" 
            type="danger" handleCloseAlert={this.handleCloseAlert} />
          }
        });
        return false;
    }

    //Send data
    data['id'] = this.state.insertedId ?? 0;
    fetch('/member/register_step2', {
        method:'POST',
        body: JSON.stringify(data)
    })
    .then(res=>res.json())
    .then((result)=>{   
      if(result && typeof result.err === 'undefined' && result.response === 'success'){
        let redirectUrl = Helper.getUrlParam('redirect') ? decodeURIComponent(Helper.getUrlParam('redirect')):'/';
        window.location.href=redirectUrl;
      }
    },
    (error)=>{
      console.log(error);
    })
  }

  componentDidUpdate(){
    const $ = window.jQuery;
    $('div.loading-img').remove();
    let carts = localStorage.getItem('cart');
    carts = JSON.parse(carts)??[];
    if(carts.length)
    {
        (!$('#Header_cart span.sticky-number').length) &&
        $('#Header_cart').append(`<span class="rounded-circle d-block position-absolute sticky-number">${carts.length}</span>`);

        (!$('#Header_cart_mobile span.sticky-number').length) && 
        $('#Header_cart_mobile').append(`<span class="rounded-circle d-block position-absolute sticky-number">${carts.length}</span>`);

        let cartView = carts.map((cartItem, index)=>{
          return (
            <li className="list-group-item d-flex justify-content-start py-2" key={index}>
              <a href={cartItem.link} title={cartItem.title} className="product-image">
                <img src={cartItem.image} width={60} alt={cartItem.title} />
              </a>
              <div className="product-details">
                <p><a href={cartItem.link}>{Helper.wordLimiter(cartItem.title, 10)}</a></p>
                <strong className="text-medium">{cartItem.cnt}</strong> x  
                <span className="text-medium text-danger">
                <NumberFormat value={cartItem.price} thousandSeparator="." decimalSeparator=","
                displayType={"text"} renderText={(value)=>`${value} đ`}/>
                </span>
                <a href="/gio-hang" className="text-left viewmore py-2">Chi tiết</a>
              </div>
            </li>
          );
        })

        let cartList = 
            <ul className="text-left list-group list-group-flush">
                {cartView}
            </ul>;
        const cartListHtml = ReactDOMServer.renderToString(cartList);
        $('#Header_cart_list').removeClass('d-none').html(cartListHtml);
    }
  }
  
  render(){
    let {alertMsg, faceLogin, regStep} = this.state;
    return (
      <div className="app">
          <BreadCrumb />
          <Content alertMsg={alertMsg} 
          handleChangeDate={this.handleChangeDate} regStep={regStep} handleLogin={this.handleLogin} 
          selectedDate={this.state.selectedDate} handleSubmitRegStep1={this.handleSubmitRegStep1} 
          handleSubmitRegStep2={this.handleSubmitRegStep2}/>
          {faceLogin ? faceLogin : null}
      </div>
    )};
}

export default App;
