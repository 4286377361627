import React from 'react';
class BreadCrumb extends React.Component {
    render(){
        return (
            <div className="container">
                <div className="row">
                    <div className="breadcrumbs">
                        <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/" title="Tới trang chủ">Trang chủ</a>
                                    <span></span>
                                </li>
                                <li className="breadcrumb-item">
                                        <strong>ĐĂNG NHẬP</strong>
                                </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}
export default BreadCrumb;